import React, { useState } from "react";
import { history } from "../../../store/index";
import Button from "@material-ui/core/Button";
import { ResponsiveContainer } from "recharts";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "react-select";
import {
  NotificationManager,
  NotificationContainer
} from "react-notifications";
// import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import { PeopleFill } from "react-bootstrap-icons";
import Divider from "@material-ui/core/Divider";
import ImageUpload from "../../../app/routes/socialApps/routes/Profile/imageUpload";
import { httpClient } from "appUtility/Api";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const socialData = JSON.parse(window.localStorage.getItem("social_data"));

const CreateGroup = props => {
  const [groupDetail, setGroupDetail] = useState({
    userID: socialData && socialData.uid,
    userCode: socialData && socialData.memtoken,
    appID: 1,
    companyID: socialData && socialData.cid,
    groupName: "",
    isPublic: "",
    groupDescription: "",
    isGroupActive: 1,
    selectedFiles: null
  });

  console.log("Checking New Group Details ::>>", groupDetail);

  const privacyList = [
    { value: "1", label: "Public" },
    { value: "0", label: "Private" }
  ];

  const handleBackButton = () => {
    history.push("/app/social");
  };

  const CancelGroupCreation = () => {
    history.push("/app/social");
  };

  const handleImagePreview = info => {
    console.log("In handleImagePreview, preview as props:::>>>", info);
    setGroupDetail({ ...groupDetail, selectedFiles: info });
  };

  const CancelPhotoUpload = () => {
    setGroupDetail({ ...groupDetail, selectedFiles: null });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setGroupDetail({ ...groupDetail, [name]: value });
  };

  const handleActiveGroup = (e, mode) => {
    setGroupDetail({ ...groupDetail, isGroupActive: mode });
  };

  const handlePrivacy = info => {
    setGroupDetail({ ...groupDetail, isPublic: info });
  };

  const handleImageReset = () => {
    setGroupDetail({ ...groupDetail, selectedFiles: null });
  };

  const handleSaveSettings = () => {
    var NewGroupData = new FormData();
    if (groupDetail.selectedFiles !== null) {
      NewGroupData.append(
        "image",
        groupDetail.selectedFiles,
        groupDetail.selectedFiles.name
      );
    }
    NewGroupData.append("userID", groupDetail.userID);
    NewGroupData.append("userCode", groupDetail.userCode);
    NewGroupData.append("appID", groupDetail.appID);
    NewGroupData.append("companyID", groupDetail.companyID);
    NewGroupData.append("groupName", groupDetail.groupName);
    NewGroupData.append("groupDescription", groupDetail.groupDescription);
    NewGroupData.append("isPublic", groupDetail.isPublic.value);
    NewGroupData.append("isGroupActive", groupDetail.isGroupActive);

    httpClient.post("api/social/groups_save.php", NewGroupData).then(res => {
      if (res.data.success !== "true") {
        NotificationManager.warning(res.data.msg);
      } else {
        NotificationManager.success(res.data.msg);
        let newGroupID = res.data.groupID;
        setTimeout(() => {
          history.push(
            `/app/social/group/${groupDetail.groupName}/${newGroupID}`
          );
        }, 1000);
      }
    });
  };

  return (
    <>
      <div style={{ paddingLeft: "2%", paddingRight: "2%" }}>
        <Button
          variant="contained"
          onClick={handleBackButton}
          style={{ marginTop: "1%", marginBottom: "1%" }}
        >
          <i
            className={`zmdi zmdi-long-arrow-left jr-fs-xxl d-inline-flex align-middle`}
          />
          <span style={{ marginLeft: "6px" }}>Back</span>
        </Button>
        <br />
        <div className="page-heading d-sm-flex  align-items-sm-center">
          <h2 className="title mb-3 mb-sm-0" style={{ marginRight: "1%" }}>
            Create New Group
          </h2>{" "}
          <PeopleFill color="royalblue" size={30} />
        </div>
      </div>

      <ResponsiveContainer>
        <div className="account-settings">
          <div className="Edit-box">
            <div className="img-upload-box">
              {groupDetail.selectedFiles === null ? (
                <ImageUpload
                  handleImagePreview={handleImagePreview}
                  CancelPhotoUpload={CancelPhotoUpload}
                  editPurpose={false}
                />
              ) : (
                <>
                  <Avatar
                    alt="Uploaded photo.."
                    src={
                      groupDetail && groupDetail.selectedFiles !== null
                        ? groupDetail.selectedFiles.name
                        : "https://source.unsplash.com/2ShvY8Lf6l0/800x599"
                    }
                    style={{ height: 250, width: 250 }}
                  />
                  <br />
                  <Button
                    variant="contained"
                    className="jr-btn bg-blue-grey text-white"
                    onClick={handleImageReset}
                  >
                    Remove
                  </Button>
                </>
              )}
            </div>

            <form style={{ width: "60%" }} onSubmit={handleSaveSettings}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="col-md-6 col-12">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Group Name"
                    name="groupName"
                    value={groupDetail.groupName}
                    onChange={e => handleChange(e)}
                    margin="normal"
                    fullWidth
                  />
                </div>

                <div className="col-md-6 col-12">
                  <div style={{ marginTop: "10px" }}>
                    <InputLabel htmlFor="isPublic">Choose Privacy:</InputLabel>
                    <Select
                      name="isPublic"
                      defaultValue={privacyList[0]}
                      options={privacyList}
                      value={groupDetail.isPublic}
                      onChange={e => handlePrivacy(e)}
                    />
                  </div>
                </div>
              </div>
              <Divider variant="middle" style={{ marginBottom: "1%" }} />
              <div className="col-md-12 col-12">
                <p style={{ color: "#bbbbbb" }}>
                  <strong>
                    #Note: Regarding the selection of Privacy, choosing "public"
                    will enable all the users to see the posts and respond to
                    it, meanwhile, choosing "private will only let the users of
                    group to see the posts and respond to it.{" "}
                  </strong>
                </p>
              </div>

              <div className="col-md-12 col-12">
                <ToggleButtonGroup
                  value={groupDetail.isGroupActive}
                  exclusive
                  onChange={handleActiveGroup}
                  aria-label="text alignment"
                >
                  <ToggleButton
                    name="isGroupActive"
                    value={1}
                    aria-label="left aligned"
                  >
                    Active
                  </ToggleButton>
                  <ToggleButton
                    name="isGroupActive"
                    value={0}
                    aria-label="centered"
                  >
                    Inactive
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <br />

              <div className="col-md-12 col-12">
                <TextField
                  id="outlined-multiline-static"
                  label="Group Description"
                  name="groupDescription"
                  value={groupDetail.groupDescription}
                  onChange={e => handleChange(e)}
                  multiline
                  rows={8}
                  variant="outlined"
                  fullWidth
                />
              </div>

              <div className="account-section-buttons">
                <Button
                  variant="contained"
                  color="primary"
                  className="jr-btn text-white"
                  onClick={handleSaveSettings}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  className="jr-btn bg-blue-grey text-white"
                  onClick={CancelGroupCreation}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>

          <NotificationContainer />
        </div>
      </ResponsiveContainer>
    </>
  );
};
export default CreateGroup;
