import React from "react";
//Card
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Fab from "@material-ui/core/Fab";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import Button from "@material-ui/core/Button";
import { XCircleFill } from "react-bootstrap-icons";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
// import CardActionArea from "@material-ui/core/CardActionArea";
// import Grid from "@material-ui/core/Grid";
//Tabs
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  icon: {
    margin: theme.spacing.unit * 2
  },
  iconHover: {
    margin: theme.spacing.unit * 2,
    "&:hover": {
      color: red[800]
    }
  },
  cardHeader: {
    backgroundColor: "white"
  },
  input: {
    display: "none"
  },

  button: {
    color: blue[900],
    margin: 10
  },
  secondaryButton: {
    color: "gray",
    margin: 10
  },
  typography: {
    margin: theme.spacing.unit * 2,
    backgroundColor: "default"
  },

  searchRoot: {
    padding: "2px 4px",
    display: "flex",
    width: 400
  },
  searchInput: {
    marginLeft: 8,
    flex: 1
  },
  searchIconButton: {
    padding: 10
  },
  searchDivider: {
    width: 1,
    height: 28,
    margin: 4
  }
});

class ImageUpload extends React.Component {
  state = {
    mainState: "initial", // initial,search, gallery, uploaded
    imageUploaded: 0,
    selectedFile: null
  };

  handleUploadClick = event => {
    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function(e) {
      this.setState({
        selectedFile: [reader.result]
      });
    }.bind(this);
    console.log("Image URL::", url); // Would see a path?

    this.setState({
      mainState: "uploaded",
      selectedFile: event.target.files[0],
      imageUploaded: 1
    });
    this.props.handleImagePreview(event.target.files[0]);
  };

  renderInitialState() {
    const { classes, CancelPhotoUpload, editPurpose } = this.props;

    return (
      <React.Fragment>
        <CardContent>
          {editPurpose ? (
            <Button style={{ float: "right" }} onClick={CancelPhotoUpload}>
              <XCircleFill /> Cancel
            </Button>
          ) : null}
          {/* <Grid container justify="center" alignItems="center"> */}
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            // multiple
            type="file"
            onChange={this.handleUploadClick}
          />

          <label htmlFor="contained-button-file">
            <strong style={{ color: "#bbbbbb" }}>Upload Profile Picture</strong>
            <Fab component="span" className={classes.button}>
              <AddPhotoAlternateIcon />
            </Fab>
          </label>
          {/* </Grid> */}
        </CardContent>
      </React.Fragment>
    );
  }

  renderUploadedState() {
    return (
      <React.Fragment>
        {/* <img
          width="20%"
          className={classes.media}
          src={this.state.selectedFile}
        /> */}
        <Button
          variant="contained"
          className="jr-btn bg-blue-grey text-white"
          onClick={this.imageResetHandler}
        >
          Remove
        </Button>
      </React.Fragment>
    );
  }

  imageResetHandler = event => {
    this.setState({
      mainState: "initial",
      selectedFile: null,
      imageUploaded: 0
    });
    this.props.handleImagePreview(null);
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ marginTop: "5%" }}>
          <Card className={this.props.cardName}>
            {this.state.mainState === "initial" && this.renderInitialState()}
          </Card>
          {this.state.mainState === "uploaded" && this.renderUploadedState()}
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ImageUpload);
