import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { AddAlert, SpeakerNotesOff } from "@material-ui/icons";
import { LockFill, Globe } from "react-bootstrap-icons";

const CardMenu = props => {
  const eachGroupOptions = [
    { name: "Subscribe", icon: <AddAlert /> },
    { name: "Mute Notifications", icon: <SpeakerNotesOff /> }
  ];

  const visibilityOptions = [
    { name: "Public", icon: <Globe /> },
    { name: "Private", icon: <LockFill /> }
  ];

  const {
    menuState,
    anchorEl,
    handleRequestClose,
    groupVisibility,
    groupOptions
  } = props;

  return (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      open={menuState}
      onClose={handleRequestClose}
      MenuListProps={{
        style: {
          width: 120,
          paddingTop: 0,
          paddingBottom: 0
        }
      }}
    >
      {groupVisibility &&
        visibilityOptions.map((option, index) => (
          <MenuItem key={index} onClick={handleRequestClose}>
            {option.icon}{" "}
            <span style={{ marginLeft: "5%" }}>{option.name}</span>
          </MenuItem>
        ))}

      {groupOptions &&
        eachGroupOptions.map((option, index) => (
          <MenuItem key={index} onClick={handleRequestClose}>
            {option.icon}
            <span style={{ marginLeft: "5%" }}>{option.name}</span>
          </MenuItem>
        ))}
    </Menu>
  );
};

export default CardMenu;
