import React from "react";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { Redirect } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer
  // NotificationManager
} from "react-notifications";
import {
  //hideMessage,
  //showAuthLoader,
  GetUserVerify
} from "actions/Auth";
// import { connect } from "react-redux";
// import IconButton from "@material-ui/core/IconButton";
// import Router from "Router";
//import CircularProgress from '@material-ui/core/CircularProgress';

//const authUserState = React.createContext('isAuthenticated');
var isAuthenticated = "A";

class SignIn extends React.Component {
  //const authUserState() => ({:false});
  constructor() {
    super();

    this.state = {
      email: "",
      password: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.stopSubmission = this.stopSubmission.bind(this);
  }

  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  stopSubmission(e) {
    //NotificationManager.info('Verifying User Detail');
    // alert('onClickPreventDefault called, form will not submit');
    e.preventDefault();
  }

  /*  handleLogin() {
    NotificationManager.info("Verifying User Detail");
    setTimeout(function() {
      NotificationManager.info("Logged in Successfully.");
    }, 2000);

    setTimeout(function() {
      window.location = "/app/dashboardOne";
    }, 4000);
  }
*/
  /*
  componentDidMount() {
 
	   setTimeout(() => {
						  if (this.props.GetUserVerify == 1) {
							  alert(this.props.GetUserVerify)
						 //this.props.history.push('/');
							  return ( <Redirect to={'/ForgotPassword'}/> );
						}
				  },1000);
	  
  }
	*/
  render() {
    const { email, password } = this.state;

    if (isAuthenticated === 1) {
      return <Redirect to={"/ForgotPassword"} />;
    }

    //  const {showMessage, loader, alertMessage} = this.props;
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Mate">
              <img
                src={require("assets/images/logo.png")}
                alt="Mate"
                title="Mate"
              />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>Login to Mate</h1>
            </div>

            <div className="app-login-form">
              <form onSubmit={this.stopSubmission}>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.email" />}
                    fullWidth
                    defaultValue={email}
                    name="email"
                    value={this.state.textValue}
                    onChange={this.handleChange}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    fullWidth
                    name="password"
                    defaultValue={password}
                    onChange={this.handleChange}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button
                      type="submit"
                      onClick={() => {
                        // this.handleLogin();
                        // alert(isAuthenticated)
                        // NotificationManager.info('Verifying User Detail');
                        GetUserVerify({ email, password });
                        //alert (isAuthenticated)
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="appModule.signIn" />
                    </Button>

                    {/*<Link to="/ForgotPassword">
                      <IntlMessages id="signIn.forgotPassword"/>
                    </Link>*/}
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        {/*
        {
          loader &&
          <div className="loader-view">
            <CircularProgress/>
          </div>
        }
		

        {showMessage && NotificationManager.error(alertMessage)}
*/}
        <NotificationContainer />
      </div>
    );
  }
}

// const mapStateToProps = ({ auth }) => {
//   const { GetUserVerify } = auth;
//   return { isAuthenticated };
// };

/*
 
const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader  
})(SignIn);
 */
/*
export default({
 GetUserVerify  
})(SignIn);
*/

export default SignIn;
