import axios from 'axios';
import GlobalURL from 'appUtility/global'
/*
export default axios.create({
  //baseURL: `http://67.205.129.195/medifellows/api/public/api/v1/`
	baseURL: 'http://localhost:3000/php/'
});
*/

let localhttpclient = axios.create({
	//baseURL:'http://localhost/v2.1/php/',
	baseURL: GlobalURL[0].url,
	headers: {
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
		"Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
	}
});

let livehttpclient = axios.create({
	baseURL: GlobalURL[0].url,
	headers: {
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
		"Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
	}
});




export const httpClient = window.location.hostname === 'localhost' ? localhttpclient : livehttpclient;

